import qs                from "./querystring";
import moment            from "moment";
import { serviceTypeId } from "./serviceType";

import {
  URGENT_SERVICE_ALIADA_ID,
  ONE_TIME_SERVICE_TYPE,
} from "./constants";

export function baseUrl() {
  console.log(process.env);
  if ('REACT_APP_BASE_URL' in process.env) {
    return process.env.REACT_APP_BASE_URL;
  } else {
    return "https://aliada.mx";
  }
}

export function resultsUrl(attributes) {
  const {
    laundry,
    ironing,
    cooking,
    pets,
    serviceType,
    serviceHours,
    coordinates,
    userId,
  } = attributes;

  const skills = () => {
    const results = ['cleaning'];

    if (laundry) results.push('laundry');
    if (ironing) results.push('ironing');
    if (cooking) results.push('cooking');

    return results;
  };

  const preferences = () => {
    const results  = [];

    if (pets) results.push('pets');

    return results;
  };

  const querystring = qs.encode({
    latitude:  coordinates.latitude,
    longitude: coordinates.longitude,
    serviceHours: serviceHours,
    skills: skills(),
    serviceType: serviceType,
    preferences: preferences(),
    userId: userId,
  });

  return `/s/aliadas?${querystring}`;
}

export function aliadaProfileUrl(attributes) {
  const {
    id,
    date,
    serviceHours,
    serviceType,
    hour,
    userId,
  } = attributes;

  const querystring = qs.encode({
    date:         date.format("YYYY-MM-DD"),
    serviceHours: serviceHours,
    serviceType:  serviceType,
    hour:         hour,
    userId:       userId,
  });

  return `/aliadas/${id}?${querystring}`;
}

export function bookingUrl(attributes) {
  const {
    date,
    serviceHours,
    serviceType,
    hour,
    id,
    userId,
  } = attributes;

  const querystring = qs.encode({
    date:           date.format("YYYY-MM-DD"),
    estimatedHours: serviceHours,
    serviceTypeId:  serviceTypeId(serviceType),
    time:           `${(hour).toString().padStart(2, "0")}:00`,
    aliadaId:       id,
  });

  if (userId === undefined || userId === null) {
    return `${baseUrl()}/booking/aliadas/${id}/reservar/direccion?${querystring}`;
  }

  return `${baseUrl()}/perfil/${userId}/booking/aliadas/${id}/reservar?${querystring}`;
}

export function urgentServiceBookingUrl(serviceHours, userId) {
  const now = moment();

  const attributes = {
    date:         now,
    serviceHours: serviceHours,
    serviceType:  ONE_TIME_SERVICE_TYPE,
    hour:         now.hour(),
    id:           URGENT_SERVICE_ALIADA_ID,
    userId:       userId,
  };

  return bookingUrl(attributes);
}
